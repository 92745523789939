<template>
  <va-navbar color="primary">
    <template #left>
      <va-navbar-item>
          <router-link class="hero" :to="{name: 'Dashboard'}" v-if="isLoggedIn">Wheelhouse</router-link>
          <span v-else>Wheelhouse</span>
      </va-navbar-item>
    </template>
    <template #right>
      <va-navbar-item v-if="isLoggedIn">
        <va-icon name="person"/>
        {{ displayName }}
      </va-navbar-item>
      <va-navbar-item v-if="isLoggedIn">
        <div @click="logout" class="fakelink">
          <va-icon name="logout"/> Log Out
        </div>
      </va-navbar-item>
    </template>
  </va-navbar>
</template>

<script>
import AuthHelper from '@/repositories/AuthHelper';

export default {
  name: "Navbar",
  props: {
    displayName: String,
    isLoggedIn: Boolean,
  },
  methods: {
    logout () {
      AuthHelper.logout().then(() => this.$router.push({ name: 'LogIn' }))
    }
  }
}
</script>

<style lang="scss" scoped>
  .va-navbar {
    box-shadow: var(--va-box-shadow);
    z-index: 2;
    &__left > * {
      margin-left: 1rem;
      font-size: 150%;
    }
    &__center {
      @media screen and (max-width: 1200px) {
        .app-navbar__github-button {
          display: none;
        }
      }
      @media screen and (max-width: 950px) {
        .app-navbar__text {
          display: none;
        }
      }
    }
    @media screen and (max-width: 950px) {
      .left {
        width: 100%;
      }
      .app-navbar__actions {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
    }
  }
  .va-navbar__item > .fakelink {
    color: white;
    cursor: pointer;
  }
  .left {
    display: flex;
    align-items: center;
    & > * {
      margin-right: 1.5rem;
    }
    & > *:last-child {
      margin-right: 0;
    }
  }
  .x-flip {
    transform: scaleX(-100%);
  }
  .app-navbar__text > * {
    margin-right: 0.5rem;
    &:last-child {
      margin-right: 0;
    }
  }
  .hero:visited {
      color: white;
  }
</style>