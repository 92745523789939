import {createRouter, createWebHistory} from 'vue-router'
import Dashboard from '../views/Dashboard.vue'

const routes = [
    {
        path: '/',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
            title: "Dashboard"
        }
    },
    {
        path: '/login',
        name: 'LogIn',
        component: () => import('../views/Login.vue'),
        meta: {
            title: "Log In"
        }
    },
    {
        path: '/accounts/new',
        name: 'AccountCreate',
        component: () => import(/* webpackChunkName: "studio" */ '../views/AccountCreate.vue'),
        meta: {
            title: "Create AWS Account"
        }
    },
    {
        path: '/studios',
        name: 'StudioList',
        component: () => import(/* webpackChunkName: "studio" */ '../views/StudioList.vue'),
        props: route => ({ reload: route.query.reload }),
        meta: {
            title: "Studios"
        }
    },
    {
        path: '/studios/new',
        name: 'StudioCreate',
        component: () => import(/* webpackChunkName: "studio" */ '../views/StudioCreate.vue'),
        meta: {
            title: "Create Studio"
        }
    },
    {
        path: '/studios/:studioId',
        name: 'Studio',
        component: () => import(/* webpackChunkName: "studio" */ '../views/Studio.vue'),
        props: true,
        meta: {
            title: "Studio"
        }
    },
    {
        path: '/studios/:studioId/edit',
        name: 'StudioConfigure',
        component: () => import(/* webpackChunkName: "studio" */ '../views/StudioConfigure.vue'),
        props: true,
        meta: {
            title: "Configure Studio"
        }
    },
    {
        path: '/studios/:studioId/bootstrap',
        name: 'StudioBootstrap',
        component: () => import(/* webpackChunkName: "studio" */ '../views/StudioBootstrap.vue'),
        props: true,
        meta: {
            title: "Bootstrap Studio"
        }
    },
    {
        path: '/studios/:studioId/groups/:groupId',
        name: 'StudioGroupEdit',
        component: () => import(/* webpackChunkName: "studio" */ '../views/StudioGroupEdit.vue'),
        props: true,
        meta: {
            title: "Edit Active Directory Group Membership"
        }
    },
    {
        path: '/studios/:studioId/edit',
        name: 'StudioConfigure',
        component: () => import(/* webpackChunkName: "studio" */ '../views/StudioConfigure.vue'),
        props: true,
        meta: {
            title: "Configure Studio"
        }
    },
    {
        path: '/storage',
        name: 'StoreList',
        component: () => import(/* webpackChunkName: "storage" */ '../views/StoreList.vue'),
        meta: {
            title: "Storage"
        }
    },
    {
        path: '/storage/new',
        name: 'StoreCreate',
        component: () => import(/* webpackChunkName: "storage" */ '../views/StoreCreate.vue'),
        props: route => ({ studioId: route.query.studioId }),
        meta: {
            title: "Create Store"
        }
    },
    {
        path: '/storage/:studioId/:label',
        name: 'Store',
        component: () => import(/* webpackChunkName: "storage" */ '../views/Store.vue'),
        props: true,
        meta: {
            title: "Store"
        }
    },
    {
        path: '/permissions',
        name: 'PermissionList',
        component: () => import(/* webpackChunkName: "permissions" */ '../views/PermissionList.vue'),
        meta: {
            title: "Permissions"
        }
    },
    {
        path: '/permissions/:type/new',
        name: 'PermissionCreate',
        component: () => import(/* webpackChunkName: "permissions" */ '../views/PermissionEditor.vue'),
        props: true,
        meta: {
            title: "New Permission Set"
        }
    },
    {
        path: '/permissions/:type/:name/edit',
        name: 'PermissionEdit',
        component: () => import(/* webpackChunkName: "permissions" */ '../views/PermissionEditor.vue'),
        props: true,
        meta: {
            title: "Edit Permissions"
        }
    },
    {
        path: '/machine-tokens/new',
        name: 'MachineTokenCreate',
        component: () => import(/* webpackChunkName: "permissions" */ '../views/MachineTokenCreate.vue'),
        props: true,
        meta: {
            title: "New Machine Token"
        }
    },
    {
        path: '/config',
        name: 'ConfigList',
        component: () => import(/* webpackChunkName: "config" */ '../views/ConfigList.vue'),
        meta: {
            title: "Configuration"
        }
    },
    {
        path: '/config/new',
        name: 'ConfigCreate',
        component: () => import(/* webpackChunkName: "config" */ '../views/ConfigEditor.vue'),
        props: true,
        meta: {
            title: "New Configuration"
        }
    },
    {
        path: '/config/:category/:ckey/edit',
        name: 'ConfigEdit',
        component: () => import(/* webpackChunkName: "config" */ '../views/ConfigEditor.vue'),
        props: true,
        meta: {
            title: "Edit Configuration"
        }
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: () => import('../views/NotFound.vue'),
        meta: {
            title: "Not Found"
        }
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    // This goes through the matched routes from last to first, finding the closest route with a title.
    // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
    // `/nested`'s will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) {
        document.title = `${nearestWithTitle.meta.title} | Wheelhouse`;
    }

    next()
})

export default router
