import store from "@/store";
import axios from "axios";
import { computed } from 'vue';

let api = axios.create({
    baseURL: process.env.VUE_APP_BASE_API_URL
})

export default {
    login(username, password, domain) {
        return api.post(
            "/auth/login",
            {
                username: username,
                password: password,
                domain: domain
            }
        ).then((response) => {
            return store.dispatch('login', {
                refresh_token: response.data.refresh_token,
                token: response.data.token
            })
        })
    },
    refresh() {
        let refresh_token = computed(() => store.state.refresh_token)
        if (!refresh_token.value) {
            // eslint-disable-next-line
            refresh_token = {value: localStorage.getItem('refresh_token')}
            if (!refresh_token.value) {
                // No refresh token to be found anywhere
                return Promise.reject()
            }
        }
        return api.post(
            "/auth/refresh",
            {
                refresh_token: refresh_token.value,
            }
        ).then((response) => {
            return store.dispatch('login', {
                refresh_token: response.data.refresh_token,
                token: response.data.token
            })
        })
    },
    logout() {
        return store.dispatch('logout')
    },
};
