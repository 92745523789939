<template>
    <va-sidebar :minimized="minimized" minimizedWidth="64px">
        <va-accordion v-model="accordionValue" multiply>
            <va-collapse
                v-for="(route, idx) in items"
                :key="idx"
                 :class="{ expanded: accordionValue[idx] && route.children }"
            >
                <template #header>
                    <va-sidebar-item
                        :active="isRouteActive(route)"
                        :to="{ name: route.route_id }"
                    >
                        <va-sidebar-item-content>
                            <va-icon :name="route.icon"/>
                            <va-sidebar-item-title>
                                {{ route.title }}
                            </va-sidebar-item-title>
                            <va-icon v-if="route.children" class="ml-auto" :name="accordionValue[idx] ? 'expand_less' : 'expand_more'"/>
                        </va-sidebar-item-content>
                    </va-sidebar-item>
                </template>
                <template v-if="route.children">
                    <va-sidebar-item
                        v-for="(child, index) in route.children.filter(it => hasScope(it.scope))" :key="index"
                        :active="isRouteActive(child)"
                        :to="{ name: child.route_id }"
                    >
                        <va-sidebar-item-content>
                            <va-icon :name="child.icon"/>
                            <va-sidebar-item-title>
                                {{ child.title }}
                            </va-sidebar-item-title>
                        </va-sidebar-item-content>
                    </va-sidebar-item>
                </template>

            </va-collapse>
        </va-accordion>
    </va-sidebar>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    data() {
        return {
            accordionValue: [false, false, false, false, false],
            items: [
                {title: 'Dashboard', route_id: 'Dashboard', icon: 'dashboard', active: true, prefix: 'Dashboard'},
                {title: 'Studios', route_id: 'StudioList', icon: 'work_outline', prefix: 'Studio'},
                {title: 'Storage', route_id: 'StoreList', icon: 'storage', prefix: 'Store'},
                {
                    title: 'Admin', prefix: 'Admin', icon: 'admin_panel_settings', children: [
                        {title: 'Configuration', prefix: "Config", route_id: 'ConfigList', icon: 'settings', scope: '*:config:get'},
                        {title: 'Permissions', prefix: "Permission", route_id: 'PermissionList', icon: 'security', scope: '*:permissions:get'},
                    ]
                },
            ],
            minimized: false,
        }
    },
    computed: {
        ...mapGetters(["hasScope"])
    },
    methods: {
        isRouteActive(item) {
            return this.$route.name && (item.route_id === this.$route.name || this.$route.name.startsWith(item.prefix))
        },
        isItemExpanded(item) {
            if (!item.children) {
                return false;
            }
            const isCurrentItemActive = this.isRouteActive(item);
            const isChildActive = !!item.children.find(child =>
                child.children ? this.isItemExpanded(child) : this.isRouteActive(child)
            );
            return isCurrentItemActive || isChildActive;
        }
    },
}
</script>

<style lang="scss">
.va-sidebar {
    .va-collapse__body {
        margin-top: 0 !important;
    }

    &__menu {
        padding: 2rem 0;

        &__inner {
            padding-bottom: 8rem;
        }
    }

    &__title {
        flex-grow: 0 !important;
    }

    &-item {
        &-content {
            padding: 0.75rem 1rem;
        }

        &__icon {
            width: 1.5rem;
            height: 1.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .va-icon {
            margin-right: 1rem;
        }
    }
}
</style>

<style lang="scss" scoped>
.va-sidebar {
    flex-shrink: 0;
}

// .va-sidebar--minimized {
//   width: auto !important;
// }
</style>