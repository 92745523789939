<template>
    <span v-if="hoursAndMinutes" class="italic">
        <template v-if="short">{{hoursAndMinutes[0]}}h {{hoursAndMinutes[0]}}m</template>
        <template v-else>
            <template v-if="hoursAndMinutes[0] === 1">1 hour, </template>
            <template v-else-if="hoursAndMinutes[0] > 1">{{hoursAndMinutes[0]}} hours, </template>
            <template v-if="hoursAndMinutes[1] === 1">1 minute</template>
            <template v-else>{{hoursAndMinutes[1]}} minutes</template>
        </template>
    </span>
    <span v-else>{{ placeholder }}</span>
</template>

<script>
import * as dayjs from 'dayjs'
const utc = require('dayjs/plugin/utc')
const localizedFormat = require('dayjs/plugin/localizedFormat')

export default {
    name: 'AppDuration',
    props: {
        start: String,
        end: String,
        placeholder: String,
        short: Boolean
    },
    computed: {
        hoursAndMinutes() {
            dayjs.extend(utc)
            dayjs.extend(localizedFormat)

            if (!this.start || !this.end) {
                return null
            }

            const start = dayjs(this.start)
            const end = dayjs(this.end)

            let minutes = end.diff(start, 'minutes');
            const hours = Math.floor(minutes / 60);
            minutes = minutes - (hours * 60);

            return [hours, minutes]
        }
    }
}
</script>

<style scoped lang="scss">
.italic {
    font-style: italic;
}
</style>
