import Client from '@/repositories/AxiosClient';

const resource = '/auth';

export default {
    list() {
        return Client().get(`${resource}/permissions`)
    },
    listPermissionSet(type) {
        return Client().get(`${resource}/permissions/${type}/`)
    },
    get(type, name) {
        return Client().get(`${resource}/permissions/${type}/${name}`)
    },
    set(payload) {
        return Client().post(`${resource}/permissions/`, payload)
    },
    listMachineTokens() {
        return Client().get(`${resource}/machine_token`)
    },
    putMachineToken(payload) {
        return Client().post(`${resource}/machine_token`, payload)
    },
    getGroupMembers(name) {
        return Client().get(`${resource}/groups/${name}`)
    },
    modifyGroupMembers(name, payload) {
        return Client().post(`${resource}/groups/${name}`, payload)
    },
};
