import StoreRepository from './StoreRepository';
import StudioRepository from './StudioRepository';
import ConfigRepository from "@/repositories/ConfigRepository";
import PermissionRepository from "@/repositories/PermissionRepository";

const repositories = {
    'stores': StoreRepository,
    'studios': StudioRepository,
    'config': ConfigRepository,
    'permission': PermissionRepository
}
export default {
    get: name => repositories[name]
};