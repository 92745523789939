import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {createVuestic} from 'vuestic-ui'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
import 'vuestic-ui/css'
import config from '../vuestic.config.js'

const app = createApp(App)

// Adapted for Vue3 https://vuejs.org/v2/guide/components-registration.html
// Register components named App....vue globally so they can be used anywhere without explicit import
const requireComponent = require.context(
  // The relative path of the components folder
  './components',
  // Whether or not to look in subfolders
  false,
  // The regular expression used to match base component filenames
  /App[A-Z]\w+\.(vue|js)$/
)

requireComponent.keys().forEach(fileName => {
  // Get component config
  const componentConfig = requireComponent(fileName)

  // Get PascalCase name of component
  const componentName = upperFirst(
    camelCase(
      // Gets the file name regardless of folder depth
      fileName
        .split('/')
        .pop()
        .replace(/\.\w+$/, '')
    )
  )

  // Register component globally
  app.component(
    componentName,
    // Look for the component options on `.default`, which will
    // exist if the component was exported with `export default`,
    // otherwise fall back to module's root.
    componentConfig.default || componentConfig
  )
})

app
    .use(store)
    .use(router)
    .use(createVuestic({ config }))
    .mount('#app')
