import Client from '@/repositories/AxiosClient';
const resource = '/studio';

export default {
    list() {
        return Client().get(`${resource}/`).then((response) => {
            response.data.studios = response.data.studios.sort((a, b) => (a.studio_id > b.studio_id) ? 1 : -1)
            return response
        });
    },
    get(studioId) {
        return Client().get(`${resource}/${studioId}`)
    },
    create(payload) {
        return Client().post(`${resource}`, payload)
    },
    delete(studioId, immediate) {
        return Client().delete(`${resource}/${studioId}`, {data: {immediate}})
    },
    update(studioId, payload) {
        return Client().put(`${resource}/${studioId}`, payload)
    },
    checkBootstrap(studioId) {
        return Client().get(`${resource}/${studioId}/bootstrap`)
    },
    bootstrap(studioId, payload) {
        return Client().post(`${resource}/${studioId}/bootstrap`, payload)
    },
    deploy(studioId, payload) {
        return Client().post(`${resource}/${studioId}/deploy`, payload)
    },
    createAccount(payload) {
        return Client().post(`${resource}/accounts`, payload)
    },
};
