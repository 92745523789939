<template>
    <div class="flex pb-4">
        <div>
            <va-breadcrumbs>
                <va-breadcrumbs-item label="Dashboard" />
            </va-breadcrumbs>
        </div>
    </div>

    <div>
        Welcome
    </div>
</template>

<script>

export default {
    name: 'Dashboard'
}
</script>
