<template>
  <div class="flex lg12 xs12 py-4" v-if="visible">
    <va-progress-circle indeterminate size="medium" :thickness="0.1" />
  </div>
</template>

<script>
export default {
  name: 'AppLoadingIndicator',
  props: {
    visible: Boolean
  }
}
</script>

<style scoped lang="scss">
</style>
