<template>
    <div style="display: none"></div>
</template>

<script>
import axios from "axios";

export default {
    name: 'BackendUpdatedIndicator',
    data() {
        return {
            loadedBuildId: undefined,
            dismissed: false,
            interval: undefined
        }
    },
    created() {
        this.checkForUpdate(true)
        this.interval = setInterval(this.checkForUpdate, 15 * 60 * 1000); // Check every 15 minutes
    },
    methods: {
        checkForUpdate(firstRun) {
            axios.get("/buildnumber.txt").then(response => {
                if (firstRun) {
                    this.loadedBuildId = response.data
                }

                if (this.loadedBuildId !== response.data) {
                    this.$vaToast.init({
                        message: "Wheelhouse has been updated, please refresh the page",
                        duration: 15 * 60 * 1000,
                        position: 'bottom-left',
                        onClose: this.closeHandler
                    })
                }
            })
        },
        closeHandler() {
            // Stop checking for updates if a user dismisses the toast
            this.dismissed = true
            clearInterval(this.interval)
        }
    }
}
</script>

<style scoped lang="scss">
</style>
