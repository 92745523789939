import Client from '@/repositories/AxiosClient';
const resource = '/config';

export default {
    list() {
        return Client().get(`${resource}/`);
    },
    get(category, key) {
        return Client().get(`${resource}/${category}/${key}`);
    },
    create(payload) {
        return Client().post(`${resource}`, payload);
    },
    update(category, key, payload) {
        return Client().put(`${resource}/${category}/${key}`, payload);
    },
};
