import {createStore} from 'vuex'
import createCache from 'vuex-cache';

import Repository from "@/repositories/RepositoryFactory";

const StudioRepository = Repository.get("studios");
const ConfigRepository = Repository.get("config");

export default createStore({
    plugins: [createCache()],
    state: {
        isSidebarMinimized: false,
        userName: "",
        displayName: "",
        isLoggedIn: false,
        token: "",
        refresh_token: "",
        studios: [],
        scopes: [],
        config: {}
    },
    mutations: {
        updateSidebarCollapsedState(state, isSidebarMinimized) {
            state.isSidebarMinimized = isSidebarMinimized
        },
        login(state, payload) {
            state.isLoggedIn = true
            state.userName = payload.username
            state.displayName = payload.displayName
            state.token = payload.token
            state.refresh_token = payload.refresh_token
            state.scopes = payload.scopes
        },
        logout(state) {
            state.isLoggedIn = false
            state.userName = ""
            state.displayName = ""
            state.token = ""
            state.refresh_token = ""
        },
        loadStudios(state, payload) {
            state.studios = payload
        },
        storeConfig(state, payload) {
            state.config[payload.key] = payload.value
        }
    },
    actions: {
        login({commit}, payload) {
            try {
                let jwtPayload = JSON.parse(atob(payload.token.split('.')[1]))

                localStorage.setItem('refresh_token', payload.refresh_token)
                commit('login', {
                    token: payload.token,
                    userName: jwtPayload.username,
                    displayName: jwtPayload.display_name,
                    scopes: jwtPayload.scope.split(' '),
                    refresh_token: payload.refresh_token
                })

            } catch (e) {
                return null
            }
        },
        logout({commit}) {
            localStorage.removeItem('refresh_token')
            return commit("logout")
        },
        getStudios({commit, state}, payload) {
            if (state.studios.length > 0 && !payload?.reload) {
                return state.studios
            }
            return StudioRepository.list().then((response) => {
                commit('loadStudios', response.data.studios)
                return response.data.studios
            })
        },
        getConfig({commit, state}, request) {
            const stringKey = `${request.category}:${request.key}`
            if (stringKey in state.config) {
                return state.config[stringKey]
            }
            return ConfigRepository.get(request.category, request.key).then(response => {
                commit("storeConfig", {key: stringKey, value: response.data.value})
                return response.data.value
            })
        }
    },
    getters: {
        hasScope: (state) => (requiredScope) => {
            let isAuthorized = false
            let studioId = null, component, action

            if ((requiredScope.match(/:/g) || []).length === 2) {
                [studioId, component, action] = requiredScope.split(':')
            } else {
                [component, action] = requiredScope.split(':')
            }

            for (const scope of state.scopes) {
                if (scope === "*:*:*") {
                    // Shortcut for admins who have access to everything
                    isAuthorized = true
                    break
                }
                const parts = scope.split(':')
                if ((studioId == null || parts[0] === "*" || parts[0] === studioId)
                    && (parts[1] === "*" || parts[1] === component)
                    && (parts[2] === "*" || parts[2] === action || action === "*")) {

                    isAuthorized = true
                    break
                }
            }

            return isAuthorized
        }
    },
    modules: {}
})
