// Do not insert custom values to the colors or breakpoints Vuestic UI config
// or they will be overwritten! Use Tailwind CSS config instead.

export default {
	breakpoints: {
		thresholds: {
			sm: 640,
			md: 768,
			lg: 1024,
			xl: 1280,
			'2xl': 1536,
		},
	},
	colors: {
		variables: {
			primary: '#2C82E0',
			white: '#fff',
			black: '#000',
		},
	},
}